import { MENU_REPORTS_FEATURE_FLAG, REPORTS } from 'constants/index';

export const getItemPath = ({
  id,
  parent,
  fullParent,
  children,
  workspaceId
}) => {
  const firstSubItem = children && Object.keys(children)[0];

  const currentPath = parent ? `/${parent}/${id}` : `/${id}`;
  const fullPath = firstSubItem
    ? `${currentPath}/${firstSubItem}`
    : currentPath;

  const currentLink = fullParent
    ? `${fullParent}/${id}`
    : `/${workspaceId}${fullPath}`;

  return {
    current: currentPath,
    link: currentLink,
    path: fullPath
  };
};

export const canShowMenuItem = ({ route, item, growthbook }) => {
  if (!item || item.allowShowInMenu === false) {
    return false;
  }

  const featureFlagsByRoute = {
    [REPORTS]: MENU_REPORTS_FEATURE_FLAG
  };

  const featureFlag = featureFlagsByRoute[route];

  if (featureFlag) {
    return growthbook.isOn(featureFlag);
  }

  return true;
};
