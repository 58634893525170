import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { EMAIL } from 'constants/index.js';

import {
  FormChanneleSelect,
  FormContactSelect,
  FormCustomSelect
} from 'components/common/hook-form';
import Button from 'components/common/button';
import { EmailChannelIcon, WhatsAppChannelIcon } from 'components/common/icons';
import handleContactFields from 'components/contacts-view/drawers/write-first/utils';

import removeSpecialCharactersPhone from 'utils/remove-special-characters-phone';

const mapChannelOptions = channel => ({
  value: channel.id,
  label: {
    title: `${channel.name} (${channel.kind === EMAIL ? '' : '+'}${
      channel.source
    })`,
    email: channel.source,
    uuid: channel.uuid
  }
});

const MainForm = ({ defaultValues, handleSubmit, isLoading, kind }) => {
  const [options, setOptions] = useState([]);

  const { t } = useTranslation(['ComposeEmail', 'ComposeWhatsApp', 'Errors']);

  const methods = useForm({
    defaultValues: {
      channel: mapChannelOptions(defaultValues.channel),
      contact: {
        label: defaultValues.contact,
        value: defaultValues.contact.id
      },
      email: '',
      phone: ''
    }
  });

  const contactField = methods.watch('contact');
  const emailField = methods.watch('email');
  const phoneField = methods.watch('phone');
  const channelField = methods.watch('channel');

  useEffect(() => {
    const fields = handleContactFields({
      contact: contactField,
      name: kind === EMAIL ? 'email' : 'phone',
      methods
    });

    setOptions(fields);
  }, [contactField, kind, methods]);

  const transformValues = values => ({
    label: {
      text: emailField
        ? t('EmailFromTo', {
            ns: 'ComposeEmail',
            fromEmail: channelField.label.email,
            toEmail: emailField.value
          })
        : t('MessageFromTo', {
            ns: 'ComposeEmail',
            fromPhone: channelField.label.email,
            toPhone: phoneField.value
          }),
      index: kind === EMAIL ? 4 : 5,
      channelKind: kind,
      channelUuid: values.channel.label.uuid,
      iconComponent: kind === EMAIL ? EmailChannelIcon : WhatsAppChannelIcon
    },
    email: values.email ? values.email.value : '',
    phone: values.phone ? removeSpecialCharactersPhone(values.phone.value) : '',
    channel: values.channel.value,
    chats: values.contact.label.chats,
    channelUuid: values.channel.label.uuid
  });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(data => {
          handleSubmit(transformValues(data));
        })}
      >
        <FormChanneleSelect
          addEntityButtonData={null}
          mapEntries={mapChannelOptions}
          name="channel"
          label={t('From')}
          isSearchable={false}
          params={{
            kind,
            hasChannelRole: defaultValues.employeeId,
            isActive: true,
            lite: undefined
          }}
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
        />

        <FormContactSelect
          name="contact"
          label={t('To')}
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
          isDisabled
        />

        <FormCustomSelect
          name={kind === EMAIL ? 'email' : 'phone'}
          rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
          label={
            kind === EMAIL
              ? t('ContactEmail')
              : t('ContactWhatsApp', { ns: 'ComposeWhatsApp' })
          }
          options={options}
          isDisabled={options.length <= 1}
        />

        <div style={{ textAlign: 'right', marginTop: 8 }}>
          <Button
            width="expanded"
            htmlType="submit"
            type="primary"
            loading={isLoading}
            disabled={!options.length}
          >
            {t('ApplyBtn', { ns: 'ComposeWhatsApp' })}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

MainForm.propTypes = {
  defaultValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  kind: PropTypes.string.isRequired
};

export default MainForm;
