import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Divider, Tooltip } from 'antd';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import {
  STATUS_CONNECTED,
  STATUS_ERROR,
  WHATSAPP,
  CHANNELS_WHATSAPP_FEATURE_FLAG
} from 'constants/index';

import {
  FormMarkdownEditor,
  FormPhoneInput,
  FormSwitch,
  LabelWithTooltip,
  SubmitWrapper,
  validateMaxLength
} from 'components/common/hook-form';
import Button from 'components/common/button';
import FormEmployeeSelect from 'components/common/hook-form/select/employee';
import FormInput from 'components/common/hook-form/input';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';
import { checkPaymentAccessByRoles } from 'components/settings-view/billing/utils';

import { getChannelFactory } from 'store/channels';
import { getActiveWorkspace } from 'store/workspace';

import mapValue from 'utils/map-value';
import removeSpecialCharactersPhone from 'utils/remove-special-characters-phone';
import useRoutesService from 'services/routes';

import OperatorHours from '../../components/operator-hours';
import SlaSettings from '../../components/sla-settings';
import { transformSubmitScheduleConfigValues } from '../../components/operator-hours/utils';
import { transformSubmitSlaConfigValues } from '../../components/sla-settings/utils';
import CodeCopy from '../../components/code-copy';
import { getWhatsAppLink } from '../../utils';

import styles from './whatsapp-channel.module.scss';

const WhatsAppChannelForm = ({
  defaultValues,
  isLoading,
  isEditor,
  isOnlyView,
  onSubmit,
  setVisibleWhatsAppAuthModal
}) => {
  const {
    account: { admin },
    user
  } = useSelector(getActiveWorkspace);

  const isChannelsWhatsappEnabled = useFeatureIsOn(
    CHANNELS_WHATSAPP_FEATURE_FLAG
  );

  const routes = useRoutesService();

  const methods = useForm({ defaultValues });

  const { t } = useTranslation([
    'ConnectWhatsApp',
    'Errors',
    'Toast',
    'ChannelSettings'
  ]);

  const channel =
    useSelector(state => getChannelFactory(state)(defaultValues.id)) || {};

  const isActiveScheduleWatch = methods.watch('scheduleConfig.isActive');

  const isAuthError =
    defaultValues.kind === WHATSAPP && defaultValues.status === STATUS_ERROR;

  const isPaidError = isChannelsWhatsappEnabled ? false : !channel.isPaid;

  const link = getWhatsAppLink(
    removeSpecialCharactersPhone(defaultValues.source)
  );
  const renderErrorMessage = (isText = true) => {
    if (isPaidError && !checkPaymentAccessByRoles(user.roles)) {
      return !isText
        ? routes.toUserWorkspace({ employeeId: admin.id })
        : t('ContactCompanyAdmin', { ns: 'ChannelSettings' });
    }

    if (isPaidError && checkPaymentAccessByRoles(user.roles)) {
      return !isText
        ? routes.toBilling()
        : t('PayPhoneNumber', { ns: 'ChannelSettings' });
    }

    if (isAuthError) {
      return !isText
        ? setVisibleWhatsAppAuthModal(defaultValues)
        : t('UpdateSourceDataBtn');
    }

    return null;
  };

  const handleSubmit = ({
    responsible,
    operators = [],
    isAskFeedback,
    source,
    scheduleConfig,
    nonWorkingTimeMessage,
    slaConfig,
    name,
    ...values
  }) =>
    onSubmit({
      ...values,
      name,
      kind: WHATSAPP,
      source: removeSpecialCharactersPhone(source),
      responsible: responsible.value,
      operators: operators.map(mapValue),
      status: channel.status,
      config: {
        name,
        chatpushInstance: defaultValues.config.chatpushInstance || undefined,
        scheduleConfig: transformSubmitScheduleConfigValues({
          ...scheduleConfig,
          nonWorkingTimeMessage
        }),
        slaConfig: transformSubmitSlaConfigValues(slaConfig)
      }
    });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleSubmit)}
        className={styles.root}
      >
        {isEditor && defaultValues.status === STATUS_CONNECTED && (
          <FormSwitch
            name="isActive"
            label={t('GetMessagesFromChannelToggle')}
            disabled={isOnlyView}
          />
        )}

        <FormInput
          name="name"
          label={
            <LabelWithTooltip
              label={t('ChannelName')}
              tooltip={t('ChannelNameTip')}
            />
          }
          placeholder={t('EnterChannelName')}
          rules={{
            required: t('RequiredField', { ns: 'Errors' }),
            maxLength: validateMaxLength(250)
          }}
          disabled={isOnlyView}
        />

        <FormPhoneInput
          name="source"
          label={t('Source')}
          rules={{
            required: !isEditor ? t('RequiredField', { ns: 'Errors' }) : true
          }}
          itemProps={{
            className: styles.mb0
          }}
          disabled={isEditor}
        />

        {(isAuthError || isPaidError) && isEditor && (
          <div className={styles.statusEmailError}>
            <Typography.Text color="red" size="small">
              {isPaidError ? t('PhoneUnpaid') : t('AuthorizationFailTip')}{' '}
              <Button
                onClick={() => renderErrorMessage(false)}
                className={styles.updateAuthBtn}
                type="link"
                disabled={isAuthError && isOnlyView}
              >
                {' '}
                {renderErrorMessage()}
              </Button>
            </Typography.Text>
          </div>
        )}

        <FormEmployeeSelect
          name="responsible"
          label={t('ChannelAdmin')}
          styles={{
            container: provided => ({
              maxWidth: 320,
              width: '100%',
              ...provided
            })
          }}
          itemProps={{
            className: styles.mt16
          }}
          rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
          isDisabled={isOnlyView}
        />

        <FormEmployeeSelect
          name="operators"
          label={t('ChannelOperators')}
          styles={{
            container: provided => ({
              maxWidth: 320,
              width: '100%',
              ...provided
            })
          }}
          rules={{
            validate: d =>
              !d.length ? t('RequiredField', { ns: 'Errors' }) : true
          }}
          isMulti
          isDisabled={isOnlyView}
        />

        <OperatorHours isOnlyView={isOnlyView}>
          {isActiveScheduleWatch && (
            <FormMarkdownEditor
              name="nonWorkingTimeMessage"
              label={
                <div className={styles.tooltip}>
                  {t('WelcomeMessageNotWorkingHours')}
                  <Tooltip title={t('WelcomeMessageNotWorkingHoursTip')}>
                    <Icon
                      type="question-circle"
                      theme="filled"
                      color="black-55"
                      size={15}
                    />
                  </Tooltip>
                </div>
              }
              toolbarHidden
              allowAttach={false}
              placeholder={t('WelcomeMessageNotWorkingHours')}
              readOnly={isOnlyView}
              uploadProps={{
                disabled: isOnlyView
              }}
              itemProps={{
                className: styles.editor
              }}
              rules={{
                validate: value => {
                  if (!value.description || !value.description.trim()) {
                    return t('RequiredField', { ns: 'Errors' });
                  }

                  return true;
                }
              }}
            />
          )}
        </OperatorHours>

        <SlaSettings isOnlyView={isOnlyView} />

        <Divider />

        {isEditor && (
          <>
            <Typography.Title level={3} className={styles.mb16}>
              {t('ConnectChatHeading')}
            </Typography.Title>

            <Typography.Paragraph color="black-45" className={styles.mb16}>
              {t('ConnectChatDesc')}
            </Typography.Paragraph>

            <CodeCopy
              link={link}
              disabled={isOnlyView}
              message={t('LinkCopied', { ns: 'Toast' })}
              className={styles.codeCopy}
            />
          </>
        )}

        {isEditor && (
          <FormSwitch
            name="notify"
            label={t('SoundNotificationsToggle')}
            disabled={isOnlyView}
          />
        )}

        <SubmitWrapper>
          <Button
            htmlType="submit"
            type="primary"
            loading={isLoading}
            size="large"
            className={styles.button}
            style={{ width: '100%' }}
            disabled={isOnlyView}
          >
            {t(!isEditor ? 'NextBtn' : 'SaveBtn')}
          </Button>
        </SubmitWrapper>
      </form>
    </FormProvider>
  );
};

WhatsAppChannelForm.propTypes = {
  defaultValues: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isEditor: PropTypes.bool,
  isOnlyView: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

WhatsAppChannelForm.defaultProps = {
  defaultValues: {},
  isEditor: false,
  isOnlyView: false
};

export default WhatsAppChannelForm;
