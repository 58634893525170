import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Input, Spin } from 'antd';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import api from 'api';
import { useTranslation } from 'react-i18next';

import FormItem from '../form-item';

import styles from './styles.module.scss';

const doValidate = debounce(
  (code, resolve, reject, t) =>
    api.promo
      .validatePromoCode(code)
      .then(({ data }) => resolve(get(data, 'message', t('PromoCodeAccepted'))))
      .catch(({ response }) =>
        reject(get(response, 'data.code.0', t('Error')))
      ),
  400,
  { leading: true }
);

export const PromoCode = ({
  rules,
  name,
  label,
  itemProps,
  methods,
  ...props
}) => {
  const { t } = useTranslation('Common');

  const { control, formState } = useFormContext();

  const [successMessage, setSuccessMessage] = useState();

  const [isValidating, setIsValidating] = useState(false);

  const validator = async data => {
    if (isEmpty(data)) {
      return true;
    }

    setIsValidating(true);

    const createOnResponse = isSuccess => message => {
      setIsValidating(false);

      if (isSuccess) {
        setSuccessMessage(message);
        methods.clearErrors(name);

        return true;
      }

      setSuccessMessage();
      return message;
    };

    return doValidate(data, createOnResponse(true), createOnResponse(false), t);
  };

  return (
    <>
      <FormItem
        label={label}
        name={name}
        {...itemProps}
        errors={formState.errors}
      >
        <Controller
          control={control}
          rules={{
            validate: v => validator(v),
            ...rules
          }}
          render={({ field }) => (
            <Input
              suffix={
                <Spin
                  spinning={isValidating}
                  size="small"
                  style={{ display: 'flex' }}
                />
              }
              autoComplete="off"
              {...field}
              {...props}
            />
          )}
        />
      </FormItem>

      {successMessage && (
        <div className={styles.successMessage}>{successMessage}</div>
      )}
    </>
  );
};

PromoCode.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object
};

PromoCode.defaultProps = {
  rules: {},
  itemProps: {}
};

export default PromoCode;
