import { lazy } from 'react';

const PAGE_HAS_BEEN_FORCE_REFRESHED = 'page-has-been-force-refreshed';

export const lazyWithRetry = componentImport =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(PAGE_HAS_BEEN_FORCE_REFRESHED) || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, 'true');
        return window.location.reload();
      }

      throw error;
    }
  });

export default lazyWithRetry;
