/* eslint-disable import/imports-first */
/* eslint-disable import/order */
/* eslint-disable import-helpers/order-imports */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { initializeApp } from 'firebase/app';

import rootReducer from 'store';
import { initSocketIOHandler } from 'socket';
import { config } from 'config';
import App from 'components/app';
import DeviceDetectionProvider from 'components/common/device-detection-provider';
import { DocumentTitleProvider } from 'components/common/document-title/document-title-context';
import GrowthBookClientProvider from 'components/app/feature-flag/growth-book-client-provider';
import * as Sentry from '@sentry/react';
import * as amplitude from '@amplitude/analytics-browser';
import DeepLinksHandler from './components/common/deep-links-handler';
import { initGTM } from 'hooks/analytics/utils';

import packageJson from '../package.json';

import './i18n-config';

import * as serviceWorker from './serviceWorker';

import './theme/common.scss';

export const history = createBrowserHistory();

amplitude.init(config.REACT_APP_AMPLITUDE_API_KEY, {
  autocapture: true,
  appVersion: packageJson.version,
  minIdLength: 1
});

export const store = createStore(
  rootReducer(history),
  composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history)))
);

export const firebaseConfig = {
  apiKey: config.REACT_APP_FIREBASE_API_KEY || true,
  projectId: config.REACT_APP_FIREBASE_PROJECT_ID || true,
  messagingSenderId: config.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || true,
  appId: config.REACT_APP_FIREBASE_APP_ID || true
};
initializeApp(firebaseConfig);

Sentry.init({
  dsn: config.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({
      beforeStartSpan: context => ({
        ...context,
        name: window.location.href
      })
    })
  ],
  environment: config.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  autoSessionTracking: true,
  tracesSampleRate: 0.5
});

initSocketIOHandler(store.dispatch, store.getState);

initGTM(config.REACT_APP_ENVIRONMENT);

ReactDOM.render(
  <DeepLinksHandler history={history}>
    <Provider store={store}>
      <DocumentTitleProvider>
        <DeviceDetectionProvider>
          <ConnectedRouter history={history}>
            <GrowthBookClientProvider>
              <App />
            </GrowthBookClientProvider>
          </ConnectedRouter>
        </DeviceDetectionProvider>
      </DocumentTitleProvider>
    </Provider>
  </DeepLinksHandler>,
  document.getElementById('root')
);

serviceWorker.unregister();
