import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Translation, useTranslation } from 'react-i18next';

import { DATE_TIME_FORMAT } from 'constants/index';

import UserCardSmall from 'components/common/user-card-small';
import ActionsDropdown from 'components/common/actions';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';

import { OPTIONS, OWNER } from './form';
import ChangeDateModal from './change-date-modal';

import styles from './access-settings.module.scss';

const User = ({
  data,
  isContact,
  filtredOptions,
  onDeleteAccess,
  onEditAccess
}) => {
  const [visibleChangeDateModal, setVisibleChangeDateModal] = useState(false);

  const { t } = useTranslation('MyDriveAccessSettings');

  const actions = [
    ...filtredOptions.map(o => ({
      title: t(o.label, { ns: o.ns }),
      allow: true,
      onClick: () =>
        onEditAccess({
          entityId: data.id,
          isContact,
          data: { role: o.value }
        })
    })),
    {
      title: t('DeleteAccessAction'),
      allow: true,
      className: styles.delete,
      onClick: () => onDeleteAccess({ entityId: data.id, isContact })
    },
    {
      title: t('ChangeExpirationDateAccessAction'),
      allow: isContact,
      onClick: () => setVisibleChangeDateModal(true)
    }
  ];

  const role = OPTIONS.find(o => o.value === data.role);

  return (
    <>
      <div className={styles.user}>
        <div>
          <UserCardSmall {...data} />

          {data.to && (
            <Typography.Text size="small">
              {t('CloseAccess')}
              {`: ${moment(data.to).format(DATE_TIME_FORMAT)}`}
            </Typography.Text>
          )}
        </div>

        {filtredOptions.length > 0 && (
          <ActionsDropdown actions={actions} btnClassName={styles.actions}>
            <Translation ns={role.ns}>
              {translate => translate(role.label)}
            </Translation>{' '}
            <Icon type="arrow" size={16} />
          </ActionsDropdown>
        )}

        {!filtredOptions.length && data.role !== OWNER && (
          <Typography.Text color="black-45" style={{ textAlign: 'right' }}>
            <Translation ns={role.ns}>
              {translate => translate(role.label)}
            </Translation>
          </Typography.Text>
        )}

        {data.role === OWNER && (
          <Typography.Text color="black-45" style={{ textAlign: 'right' }}>
            {t('Owner')}
          </Typography.Text>
        )}
      </div>

      <ChangeDateModal
        visible={visibleChangeDateModal}
        onClose={() => setVisibleChangeDateModal(false)}
        defaultValues={{ to: moment(data.to).toDate() }}
        onSubmit={({ to }) => {
          onEditAccess({
            entityId: data.id,
            isContact,
            data: { to }
          });

          setVisibleChangeDateModal(false);
        }}
      />
    </>
  );
};

User.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  })
};

User.defaultProps = {
  data: {}
};

export default User;
