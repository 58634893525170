import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { INVITE_DATA } from 'constants/index';

import Button from 'components/common/button';
import Typography from 'components/common/typography';
import {
  FormInput,
  FormPhoneInput,
  PromoCode,
  validatePhone,
  validateMaxLength,
  validateRequired,
  validateMinLength
} from 'components/common/hook-form';

import useAnalytics from 'hooks/analytics/use-analytics';
import { useLocalStorage } from 'hooks/common';

import styles from './styles.module.scss';

const { Title } = Typography;

const CompleteUserForm = ({ data, isLoading, isOAuth, onSubmit }) => {
  const [inviteData] = useLocalStorage(INVITE_DATA, {});

  const methods = useForm({
    defaultValues: {
      lastName: data.lastName,
      firstName: data.firstName,
      phone: data.phone || inviteData.phone
    }
  });

  const { t } = useTranslation(['CompleteProfile', 'Errors']);

  const { reachGoal, trackPageView } = useAnalytics(true);

  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async values => {
    await onSubmit(values);

    reachGoal('reg_step3', 'registration');
  };

  return (
    <FormProvider {...methods}>
      <form
        data-qa="qa-4oj00e0ophl4ke6"
        className={classnames(styles.form, styles.maxWidth)}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <Title level={1} className={styles.title} data-qa="qa-u2psps0mxdblcfj">
          {t('CompleteProfileHeading')}
        </Title>

        <FormInput
          label={t('LastName')}
          name="lastName"
          data-qa="qa-q3vzun6g1588yvm"
          rules={{
            required: validateRequired(),
            minLength: validateMinLength(2),
            maxLength: validateMaxLength(40)
          }}
        />

        <FormInput
          label={t('FirstName')}
          name="firstName"
          data-qa="qa-sekp5fpg0q13vat"
          rules={{
            required: validateRequired(),
            minLength: validateMinLength(2),
            maxLength: validateMaxLength(40)
          }}
        />

        <FormPhoneInput
          label={t('PhoneNumber')}
          name="phone"
          data-qa="qa-7ql3yutznrfw7wh"
          rules={{
            validate: v =>
              validatePhone(
                v,
                { isOnlyNumber: true },
                t('ValidateIsPhone', { ns: 'Errors' })
              )
          }}
        />

        {isOAuth && (
          <PromoCode
            label={t('Promocode')}
            name="promoCode"
            data-qa="qa-xwwxu6sqspn3ckb"
            methods={methods}
            itemProps={{
              className: styles.item
            }}
          />
        )}

        <Button
          type="primary"
          htmlType="submit"
          data-qa="qa-q3bfsa68tuntps2"
          loading={isLoading}
        >
          {t('ProceedBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

CompleteUserForm.propTypes = {
  data: PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    phone: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

CompleteUserForm.defaultProps = {
  isLoading: false,
  data: {}
};

export default CompleteUserForm;
