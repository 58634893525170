import React from 'react';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  ALL,
  MENU_REPORTS_FEATURE_FLAG,
  REPORTS,
  TEMPLATES
} from 'constants/index';

import { getRouterUrlSubmenu, getRouterUrlView } from 'store/router';

import useRoutesService from 'services/routes';

const FeatureFlagGuard = ({ children }) => {
  const growthbook = useGrowthBook();
  const routes = useRoutesService({ returnUrl: true });

  const view = useSelector(getRouterUrlView);
  const subView = useSelector(getRouterUrlSubmenu);

  const featureFlagsByCondition = [
    {
      condition: view === REPORTS && (subView === ALL || subView === TEMPLATES),
      flag: MENU_REPORTS_FEATURE_FLAG
    }
  ];

  const currentFeature = featureFlagsByCondition.find(
    ({ condition }) => condition
  );

  if (currentFeature && !growthbook.isOn(currentFeature.flag)) {
    return <Redirect to={routes.toDefaultPage()} />;
  }

  return children;
};

export default FeatureFlagGuard;
