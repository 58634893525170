import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Alert } from 'antd';
import { Translation, useTranslation } from 'react-i18next';

import { REG_BOSS_INVITATION_EVENT } from 'constants/index';

import Drawer from 'components/common/drawer';
import OnboardingModal from 'components/common/onboarding/modal';
import Button from 'components/common/button';
import Typography from 'components/common/typography';
import {
  FormEmployeeSelect,
  FormInput,
  FormCheckbox,
  validateRequired,
  validateMaxLength
} from 'components/common/hook-form';

import { joinDepartment, becomeOwner } from 'store/team/departments';
import { getUserEmployee } from 'store/workspace';

import { useModalsService } from 'services/modals';
import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import getHasOwnerRole from 'utils/get-has-owner-role';
import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import styles from '../styles.module.scss';

const { Paragraph } = Typography;

const IS_DIRECTOR = 'isDirector';

const AppointmentForm = ({
  values: defaultValues,
  className,
  isLoading,
  onClickBecome,
  onSubmit,
  btnClassName
}) => {
  const amplitude = useAmplitude();

  const { t } = useTranslation('AllocationToTheDepartment');

  const methods = useForm({
    defaultValues: {
      position: defaultValues.position,
      isDirector: false
    }
  });

  const isDirectorWatch = useWatch({
    name: IS_DIRECTOR,
    control: methods.control
  });

  const handleSubmit = values => {
    if (values.isDirector) {
      onClickBecome({ position: values.position });
    } else {
      onSubmit({
        ...values,
        [values.transferTo.isNew ? 'manager_email' : 'manager']: values
          .transferTo.value
      });
    }

    if (values.transferTo.isNew) {
      amplitude.colleagueInvitationEvent([REG_BOSS_INVITATION_EVENT]);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)} className={className}>
        {defaultValues.benchReason && (
          <Alert
            message={defaultValues.benchReason}
            type="warning"
            style={{ marginBottom: 16 }}
          />
        )}

        <Paragraph>{t('AllocationToTheDepartmentDesc')}</Paragraph>

        {!isDirectorWatch && (
          <FormEmployeeSelect
            allowCreatable
            closeMenuOnSelect
            name="transferTo"
            label={t('YourImmediateSupervisor')}
            valueText={t('EnterYourNameEmail')}
            allowSetYourself={false}
            params={{
              excludeBench: true
            }}
            rules={{
              required: validateRequired()
            }}
          />
        )}

        <FormInput
          label={t('YourPosition')}
          name="position"
          placeholder={t('EnterYourPosition')}
          rules={{
            required: validateRequired(),
            maxLength: validateMaxLength(500)
          }}
        />

        <FormCheckbox name={IS_DIRECTOR}>
          {t('HeadOfCompanyChckbx')}
        </FormCheckbox>

        {isDirectorWatch && (
          <Alert message={t('HeadOfCompanyWarning')} type="warning" />
        )}

        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          className={btnClassName}
        >
          {t('NextBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

export const AppointmentDrawer = ({
  className,
  closable,
  title,
  onClose,
  ...drawerProps
}) => {
  const dispatch = useDispatch();
  const { state = {} } = useLocation();
  const modals = useModalsService();

  const user = useSelector(getUserEmployee);

  const [isLoading, setIsLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const { isAfterRegister, isInvite } = state;

  const onClickBecome = async ({ position }) => {
    await dispatch(becomeOwner({ position }));

    showNoticeMessage({ number: NOTICE_NUMBER.becomeOwnerRequestSended });

    modals.close(state);

    if (closable) {
      onClose();
    }

    if (isAfterRegister && isInvite && getHasOwnerRole(user.roles)) {
      setVisibleModal(true);
    }
  };

  const onSubmit = async values => {
    try {
      setIsLoading(true);

      await dispatch(joinDepartment(values));

      showNoticeMessage({ number: NOTICE_NUMBER.joinDepartmentRequestSended });

      modals.close(state);

      if (closable) {
        onClose();
      }

      if (isAfterRegister && isInvite && getHasOwnerRole(user.roles)) {
        setVisibleModal(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Drawer
        closable={closable}
        onClose={onClose}
        className={classnames(styles.root, className)}
        title={<Drawer.Title>{title}</Drawer.Title>}
        destroyOnClose
        {...drawerProps}
      >
        <AppointmentForm
          values={user}
          className={styles.form}
          btnClassName={styles.btn}
          isLoading={isLoading}
          onClickBecome={onClickBecome}
          onSubmit={onSubmit}
        />
      </Drawer>

      <OnboardingModal
        visible={visibleModal}
        onClose={() => {
          setVisibleModal(false);
          dispatch(push({ isAfterRegister: false }));
        }}
      />
    </>
  );
};

AppointmentDrawer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  closable: PropTypes.bool,
  onClose: PropTypes.func
};

AppointmentDrawer.defaultProps = {
  className: undefined,
  title: (
    <Translation ns="AllocationToTheDepartment">
      {t => t('AllocationToTheDepartmentHeading')}
    </Translation>
  ),
  closable: false,
  onClose: () => {}
};

export default AppointmentDrawer;
