import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Typography } from 'components/common/typography';
import Drawer from 'components/common/drawer';
import { FormInput, FormRelationsSelect } from 'components/common/hook-form';
import Button from 'components/common/button';

import { showNoticeMessage } from 'services/notice';

import { getFile } from '../add-file';

import styles from './attach-file-to-entity.module.scss';

const { Paragraph } = Typography;

export const AttachFileToEntityDrawer = ({
  file,
  visible,
  onClose,
  ...props
}) => {
  const { t } = useTranslation('AddFile');

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const match = (file.title || '').match(/(.*)\.([^.]*)$/);
  const fileName = match ? match[1] : file.title;
  const fileExtension = match ? match[2] : '';

  const methods = useForm({
    defaultValues: {
      file: fileName || ''
    }
  });

  const relationWatch = methods.watch('relation');

  const handleClose = () => {
    onClose();

    methods.reset();
  };

  const onSubmit = async ({ relation }) => {
    setIsLoading(true);

    await getFile({
      ...relation[0],
      fileList: [{ id: file.fileId, name: file.title, type: file.mimeType }],
      dispatch
    });

    handleClose();

    setIsLoading(false);

    showNoticeMessage({
      customContent: t('FileAttachedSuccess')
    });

    methods.reset();
  };

  return (
    <Drawer
      visible={visible}
      width={520}
      title={<Drawer.Title>{t('AddFileDrawerTitle')}</Drawer.Title>}
      bodyStyle={{ padding: 24 }}
      onClose={handleClose}
      {...props}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.form}>
          <Paragraph>{t('AddFileDrawerDescr')}</Paragraph>

          <FormInput
            name="file"
            label={t('FileTitle')}
            disabled
            suffix={`.${fileExtension}`}
            onChange={handleClose}
          />

          <FormRelationsSelect
            name="relation"
            label={t('Link')}
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            allowToSelectOne
          />

          <Button
            htmlType="submit"
            type="primary"
            size="large"
            className={styles.button}
            disabled={!relationWatch || !relationWatch.length || isLoading}
          >
            {t('AddFileDrawerTitle')}
          </Button>
        </form>
      </FormProvider>
    </Drawer>
  );
};

AttachFileToEntityDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  file: PropTypes.object
};

AttachFileToEntityDrawer.defaultProps = {
  visible: false,
  onClose: () => {},
  file: {}
};

export default AttachFileToEntityDrawer;
