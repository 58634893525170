import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Alert, Divider, Tooltip } from 'antd';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import {
  STATUS_CONNECTED,
  STATUS_DISCONNECTED,
  STATUS_ERROR,
  VIDEOS,
  WEBSITE,
  TELEGRAM,
  WHATSAPP,
  CHANNELS_WHATSAPP_FEATURE_FLAG
} from 'constants/index';

import FormInput from 'components/common/hook-form/input';
import FormColorInput from 'components/common/hook-form/color-input';
import FormEmployeeSelect from 'components/common/hook-form/select/employee';
import FormMarkdownEditor from 'components/common/hook-form/markdown-editor';
import FormRadio from 'components/common/hook-form/radio';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';
import Button from 'components/common/button';
import VideoBtn from 'components/common/video-btn';
import {
  FormChanneleSelect,
  FormCustomSelect,
  FormSlider,
  FormSwitch,
  LabelWithTooltip,
  SubmitWrapper,
  withoutBubbling
} from 'components/common/hook-form';

import mapValue from 'utils/map-value';
import scrollToFirstError from 'utils/scroll-to-first-error';

import WidgetPreview from './widget-preview';
import FormIconRadio from './icon-radio';
import CodeCopy from '../../components/code-copy';
import { OBSERVED_FIELDS, CHANNEL_DRAWER_WIDTH } from './utills';
import FormDistanceInput from './distance-input';
import { getWidgetLink } from '../../utils';
import OperatorHours from '../../components/operator-hours';
import { transformSubmitScheduleConfigValues } from '../../components/operator-hours/utils';
import SlaSettings from '../../components/sla-settings';
import { transformSubmitSlaConfigValues } from '../../components/sla-settings/utils';
import { LANGUAGE_OPTIONS } from '../../drawers/creator/steps/utils';

import styles from './website-channel.module.scss';

const MAX_SLIDER_VALUE = 60;
const MIN_SLIDER_VALUE = 1;

const { Text, Title } = Typography;

const LabelTitle = ({ children }) => (
  <Title level={4} style={{ marginBottom: 16 }}>
    {children}
  </Title>
);

export const WebsiteChannelForm = ({
  defaultValues,
  isEditor,
  isOnlyView,
  isLoading,
  onSubmit
}) => {
  const { watch, control, handleSubmit, ...methods } = useForm({
    defaultValues
  });

  const isFirstRender = useRef(true);

  const { t } = useTranslation(['ConnectWidget', 'Errors']);

  const isChannelsWhatsappEnabled = useFeatureIsOn(
    CHANNELS_WHATSAPP_FEATURE_FLAG
  );

  const positionWatch =
    useWatch({ name: OBSERVED_FIELDS.position, control }) || 'right';
  const bottomWatch = useWatch({ name: OBSERVED_FIELDS.bottom, control });
  const rightWatch = useWatch({ name: OBSERVED_FIELDS.right, control });
  const leftWatch = useWatch({ name: OBSERVED_FIELDS.left, control });
  const iconWatch = useWatch({ name: OBSERVED_FIELDS.icon, control });
  const messageWatch = (
    useWatch({ name: OBSERVED_FIELDS.message, control }) || {}
  ).description;
  const titleWatch =
    useWatch({ name: OBSERVED_FIELDS.title, control }) || 'Upservice';
  const colorWatch = useWatch({ name: OBSERVED_FIELDS.color, control });

  const isInviteWatch = useWatch({
    name: OBSERVED_FIELDS.isInvite,
    control
  });
  const inviteDelayWatch = useWatch({
    name: OBSERVED_FIELDS.inviteDelay,
    control
  });

  const withTelegramWatch = useWatch({
    name: OBSERVED_FIELDS.withTelegram,
    control
  });
  const telegramBotWatch = useWatch({
    name: OBSERVED_FIELDS.telegramBot,
    control
  });
  const withWhatsAppWatch = useWatch({
    name: OBSERVED_FIELDS.withWhatsapp,
    control
  });
  const whatsAppWatch = useWatch({
    name: OBSERVED_FIELDS.whatsapp,
    control
  });
  const botPositionWatch = useWatch({
    name: OBSERVED_FIELDS.botPosition,
    control
  });
  const isCallbackOnWatch = useWatch({
    name: OBSERVED_FIELDS.isCallbackOn,
    control
  });
  const isActiveScheduleConfigWatch = useWatch({
    name: OBSERVED_FIELDS.isActiveScheduleConfig,
    control
  });
  const languageWatch = useWatch({
    name: OBSERVED_FIELDS.language,
    control
  });

  const isPositionRight = positionWatch === 'right';

  const [isOpen, setIsOpen] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const [visiblePrview, setVisiblePrview] = useState(false);

  const transformSliderInputValue = event => {
    const { value } = event.target;

    if (+value > MAX_SLIDER_VALUE) {
      return MAX_SLIDER_VALUE;
    }

    return value;
  };

  const onBlurSliderInput = () => {
    if (
      isInviteWatch &&
      (!inviteDelayWatch || +inviteDelayWatch < MIN_SLIDER_VALUE)
    ) {
      return methods.setValue(
        OBSERVED_FIELDS.inviteDelay,
        defaultValues.inviteDelay
      );
    }

    return null;
  };

  const submitForm = useCallback(
    ({
      title,
      message,
      icon,
      responsible,
      operators,
      color,
      position,
      left,
      right,
      bottom,
      isInvite,
      inviteDelay,
      withTelegram,
      telegramBot,
      botPosition,
      isCallbackOn,
      scheduleConfig,
      withWhatsapp,
      whatsapp,
      slaConfig,
      displayIfOffline,
      language,
      ...values
    }) => {
      const paddings = isPositionRight
        ? [Number(right), Number(bottom)]
        : [Number(left), Number(bottom)];

      return onSubmit({
        ...values,
        source: undefined,
        kind: WEBSITE,
        responsible: (responsible || {}).value,
        operators: (operators || []).map(mapValue),
        config: {
          name: title,
          message: message.description || '',
          icon,
          color,
          position,
          paddings,
          isInvite,
          inviteDelay,
          telegramBot:
            telegramBot && withTelegram ? telegramBot.label.config.name : null,
          whatsapp: whatsapp && withWhatsapp ? whatsapp.label.source : null,
          botPosition:
            botPosition && (withTelegram || withWhatsapp) ? botPosition : null,
          isCallbackOn,
          scheduleConfig: transformSubmitScheduleConfigValues({
            ...scheduleConfig,
            workingTimeMessage: message.description || ''
          }),
          slaConfig: transformSubmitSlaConfigValues(slaConfig),
          displayIfOffline,
          language: language.value
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSubmit]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisiblePrview(true);

      isFirstRender.current = true;
    }, [500]);

    return () => {
      clearTimeout(timeout);

      setVisiblePrview(false);
    };
  }, []);

  useEffect(() => {
    if (isInviteWatch && !isFirstRender.current) {
      setIsOpen(false);
      setIsExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInviteWatch, inviteDelayWatch, isFirstRender.current]);

  useEffect(() => {
    if (!isOpen && !isFirstRender.current) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInviteWatch,
    iconWatch,
    colorWatch,
    titleWatch,
    isCallbackOnWatch,
    isFirstRender.current
  ]);

  useEffect(() => {
    if (isOpen && withTelegramWatch) {
      setIsOpen(false);
    }

    if (!withTelegramWatch) {
      setIsExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withTelegramWatch]);

  useEffect(() => {
    if (telegramBotWatch || whatsAppWatch) {
      setIsOpen(false);
      setIsExpanded(true);
    }
  }, [telegramBotWatch, botPositionWatch, whatsAppWatch]);

  useEffect(() => {
    if (isOpen) {
      setIsExpanded(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (withTelegramWatch || withWhatsAppWatch) {
      const drawerBody = document.querySelector('.ant-drawer-body');

      if (drawerBody) {
        setTimeout(
          () =>
            drawerBody.scroll({
              top: drawerBody.scrollHeight,
              behavior: 'smooth'
            }),
          200
        );
      }
    }
  }, [withTelegramWatch, withWhatsAppWatch]);

  useEffect(() => {
    scrollToFirstError(methods);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState]);

  return (
    <>
      <FormProvider watch={watch} control={control} {...methods}>
        {!isEditor && (
          <VideoBtn slug={VIDEOS.connectChannel} style={{ marginBottom: 24 }} />
        )}

        <Alert
          message={t('WidgetChannelSettingsWarning')}
          type="warning"
          style={{ marginBottom: 24 }}
        />

        <form
          className={styles.root}
          onSubmit={event =>
            withoutBubbling(event, () => handleSubmit(submitForm))
          }
        >
          <div className={styles.form}>
            {defaultValues.status === STATUS_CONNECTED && (
              <FormSwitch
                name="isActive"
                label={t('GetMessagesFromChannelToggle')}
                disabled={isOnlyView}
                itemProps={{
                  style: {
                    marginBottom: 24
                  }
                }}
              />
            )}

            <Title level={3} style={{ marginBottom: 16 }}>
              {t('SetChannelHeading')}
            </Title>

            <FormInput
              name={OBSERVED_FIELDS.name}
              label={
                <LabelWithTooltip
                  label={t('ChannelName')}
                  tooltip={t('ChannelNameTip')}
                />
              }
              placeholder={t('EnterChannelName')}
              rules={{
                required: t('RequiredField', { ns: 'Errors' }),
                maxLength: {
                  value: 250,
                  message: t('ValidateMaxLength', { ns: 'Errors', value: 250 })
                }
              }}
              disabled={isOnlyView}
            />

            {defaultValues.status === STATUS_CONNECTED && isEditor && (
              <FormCustomSelect
                name={OBSERVED_FIELDS.sources}
                label={t('Source')}
                renderContent={<div />}
                valueText={t('Source')}
                className={styles.sources}
                optionProps={{
                  style: {
                    maxWidth: 'unset'
                  }
                }}
                isMulti
              />
            )}

            <FormEmployeeSelect
              name={OBSERVED_FIELDS.responsible}
              label={t('ChannelAdmin')}
              styles={{
                container: provided => ({
                  maxWidth: 320,
                  width: '100%',
                  ...provided
                })
              }}
              rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
              isDisabled={isOnlyView}
            />

            <FormEmployeeSelect
              name={OBSERVED_FIELDS.operators}
              label={t('ChannelOperators')}
              placeholder={t('ChannelOperators')}
              styles={{
                container: provided => ({
                  maxWidth: 320,
                  width: '100%',
                  ...provided
                })
              }}
              rules={{
                validate: d =>
                  !d.length ? t('RequiredField', { ns: 'Errors' }) : true
              }}
              isMulti
              isDisabled={isOnlyView}
            />

            <OperatorHours isOnlyView={isOnlyView} isWebsiteChannel />

            <Title className={styles.welcomeMessageTitle} level={3}>
              {t('WelcomeMessageHeading')}
              <Tooltip title={t('WelcomeMessageTip')}>
                <Icon
                  type="question-circle"
                  theme="filled"
                  color="black-55"
                  size={15}
                />
              </Tooltip>
            </Title>

            <FormMarkdownEditor
              name={OBSERVED_FIELDS.message}
              label={t('WelcomeMessageWorkingHours')}
              toolbarHidden
              allowAttach={false}
              placeholder={t('WelcomeMessageWorkingHours')}
              readOnly={isOnlyView}
              uploadProps={{
                disabled: isOnlyView
              }}
            />

            {isActiveScheduleConfigWatch && (
              <FormMarkdownEditor
                name={OBSERVED_FIELDS.nonWorkingTimeMessage}
                label={t('WelcomeMessageNotWorkingHours')}
                toolbarHidden
                allowAttach={false}
                placeholder={t('WelcomeMessageNotWorkingHours')}
                readOnly={isOnlyView}
                uploadProps={{
                  disabled: isOnlyView
                }}
                rules={{
                  validate: value =>
                    !!value.description || t('RequiredField', { ns: 'Errors' })
                }}
              />
            )}

            <FormSwitch
              name={OBSERVED_FIELDS.isInvite}
              label={t('AutomatedAskToDialogToggle')}
              disabled={isOnlyView}
            />

            <Divider />

            <SlaSettings isOnlyView={isOnlyView} />

            <Divider />

            <div className={styles.appearance}>
              <Title level={3} style={{ margin: 0 }}>
                {t('ChatAppearanceHeading')}
              </Title>

              <FormCustomSelect name="language" options={LANGUAGE_OPTIONS} />
            </div>

            <FormInput
              name={OBSERVED_FIELDS.title}
              label={t('ChatTitle')}
              placeholder={t('ChatTitle')}
              rules={{
                required: t('RequiredField', { ns: 'Errors' }),
                maxLength: {
                  value: 250,
                  message: t('ValidateMaxLength', { ns: 'Errors', value: 250 })
                }
              }}
              disabled={isOnlyView}
            />

            {isInviteWatch && (
              <div style={{ display: 'flex' }}>
                <Typography.Paragraph
                  style={{ margin: '10px 16px 0 0', whiteSpace: 'nowrap' }}
                >
                  {t('ActivationTime')}
                </Typography.Paragraph>

                <FormSlider
                  name={OBSERVED_FIELDS.inviteDelay}
                  itemProps={{
                    style: {
                      width: '100%'
                    }
                  }}
                  sliderProps={{
                    max: MAX_SLIDER_VALUE,
                    style: {
                      width: '100%'
                    }
                  }}
                  inputProps={{
                    suffix: t('sec'),
                    isOnlyWhole: true,
                    style: {
                      marginLeft: 16,
                      maxWidth: 120
                    },
                    onBlur: onBlurSliderInput
                  }}
                  transformValue={transformSliderInputValue}
                />
              </div>
            )}

            <FormColorInput
              name={OBSERVED_FIELDS.color}
              label={t('ChatColor')}
              disabled={isOnlyView}
            />

            <FormIconRadio
              name={OBSERVED_FIELDS.icon}
              label={<LabelTitle>{t('ChatIconHeading')}</LabelTitle>}
              disabled={isOnlyView}
            />

            <FormRadio
              name={OBSERVED_FIELDS.position}
              label={<LabelTitle>{t('ChatPlacement')}</LabelTitle>}
              options={[
                { label: 'LeftRadio', ns: 'ConnectWidget', value: 'left' },
                { label: 'RightRadio', ns: 'ConnectWidget', value: 'right' }
              ]}
              disabled={isOnlyView}
            />

            <LabelTitle>{t('ChatSpacings')}</LabelTitle>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 16
              }}
            >
              <FormDistanceInput
                name={OBSERVED_FIELDS[positionWatch]}
                label={t(isPositionRight ? 'RightMargin' : 'LeftMargin')}
                disabled={isOnlyView}
              />

              <FormDistanceInput
                name={OBSERVED_FIELDS.bottom}
                label={t('BottomMargin')}
                disabled={isOnlyView}
              />
            </div>
          </div>

          {isEditor && (
            <>
              <Title level={3} style={{ marginBottom: 16 }}>
                {t('ConnectChatHeading')}
              </Title>

              <VideoBtn
                slug={VIDEOS.addChannelToSite}
                style={{ marginBottom: 16 }}
              />

              <CodeCopy
                link={getWidgetLink(defaultValues.uuid)}
                disabled={isOnlyView}
              />

              {defaultValues.status === STATUS_DISCONNECTED && (
                <Alert
                  message={t('ChannelOffWarning')}
                  type="warning"
                  style={{ marginBottom: 24 }}
                />
              )}

              {defaultValues.status === STATUS_ERROR && (
                <div className={styles.dangerPanel}>
                  <Text>{defaultValues.statusDescription}</Text>
                </div>
              )}
            </>
          )}

          <FormSwitch
            name={OBSERVED_FIELDS.withTelegram}
            label={t('AddTelegramToggle')}
            disabled={isOnlyView}
          />

          {/* TODO: WHATSAPP */}
          {isChannelsWhatsappEnabled && (
            <FormSwitch
              name={OBSERVED_FIELDS.withWhatsapp}
              label={t('AddWhatsAppToggle')}
              disabled={isOnlyView}
            />
          )}

          <FormSwitch
            name={OBSERVED_FIELDS.isCallbackOn}
            label={t('AskCallBackToggle')}
            disabled={isOnlyView}
          />

          {(withTelegramWatch || withWhatsAppWatch) && (
            <div>
              {withTelegramWatch && (
                <>
                  <VideoBtn
                    slug={VIDEOS.connectTelegramToSiteChat}
                    style={{ marginBottom: 16 }}
                  />

                  <FormChanneleSelect
                    name={OBSERVED_FIELDS.telegramBot}
                    label={t('TelegramChannel')}
                    params={{
                      kind: TELEGRAM,
                      lite: undefined
                    }}
                  />
                </>
              )}

              {withWhatsAppWatch && (
                <FormChanneleSelect
                  name={OBSERVED_FIELDS.whatsapp}
                  label={t('WhatsAppChannel')}
                  addEntityButtonData={undefined}
                  params={{
                    kind: WHATSAPP,
                    lite: undefined
                  }}
                />
              )}

              <FormRadio
                name={OBSERVED_FIELDS.botPosition}
                options={[
                  {
                    label: 'VerticalExpandRadio',
                    ns: 'ConnectWidget',
                    value: 'vertical'
                  },
                  {
                    label: 'HorizontalExpandRadio',
                    ns: 'ConnectWidget',
                    value: 'horizontal'
                  }
                ]}
                disabled={isOnlyView || (!telegramBotWatch && !whatsAppWatch)}
              />
            </div>
          )}

          {isEditor && (
            <FormSwitch
              name="notify"
              label={t('SoundNotificationsToggle')}
              disabled={isOnlyView}
            />
          )}

          <SubmitWrapper>
            <Button
              htmlType="submit"
              type="primary"
              loading={isLoading}
              size="large"
              style={{ width: '100%' }}
              disabled={isOnlyView}
            >
              {t('SaveBtn')}
            </Button>
          </SubmitWrapper>
        </form>
      </FormProvider>

      {visiblePrview && (
        <WidgetPreview
          right={
            isPositionRight
              ? CHANNEL_DRAWER_WIDTH + Number(rightWatch)
              : undefined
          }
          left={!isPositionRight ? Number(leftWatch) : undefined}
          expand={botPositionWatch}
          bottom={Number(bottomWatch)}
          icon={iconWatch}
          message={messageWatch}
          title={titleWatch}
          avatar={iconWatch}
          color={colorWatch}
          isInvite={isInviteWatch}
          inviteDelay={+inviteDelayWatch}
          isOpen={isOpen}
          isExpanded={isExpanded}
          channels={{
            telegram:
              withTelegramWatch && telegramBotWatch
                ? telegramBotWatch.label.config.name
                : null,
            whatsapp:
              withWhatsAppWatch && whatsAppWatch
                ? whatsAppWatch.label.source
                : null
          }}
          isCallbackOn={isCallbackOnWatch}
          language={languageWatch.value}
        />
      )}
    </>
  );
};

WebsiteChannelForm.propTypes = {
  defaultValues: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
    sources: PropTypes.array,
    responsible: PropTypes.object,
    operators: PropTypes.array,
    title: PropTypes.string,
    message: PropTypes.shape({
      description: PropTypes.string
    }),
    isActive: PropTypes.bool,
    status: PropTypes.string,
    statusDescription: PropTypes.string
  }),
  isEditor: PropTypes.bool,
  isOnlyView: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

WebsiteChannelForm.defaultProps = {
  defaultValues: {},
  isLoading: false,
  isEditor: false,
  isOnlyView: false
};

export default WebsiteChannelForm;
